import { Button, Col, Container, Row, Form } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import PlanPriceBlue from "../components/PlanPriceBlue";
import { Trans, useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { homeInternetPlans } from "../utilities/constants";
import { faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { goTo } from "../utilities/utils";
import { saveRouteDetails } from "../redux/actions/routeActions";
import { useDispatch } from "react-redux";
import { graphql } from "gatsby";
import LearnMoreSection from "../components/LearnMoreSection";
import { RootState } from "../redux/reducers/rootReducer";
import { useSelector } from "react-redux";

export default function homeInternet() {
  const dispatch = useDispatch();
  const shopDetails = useSelector((state: RootState) => state.shop);
  const [pageWidth, setPageWidth] = useState(0);
  const middlePosition = pageWidth/2;
  const [isMobile, setIsMobile] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState('Ontario');
  const { t } = useTranslation();

  const onChangeProvince = (e) => {
    setSelectedProvince(e.target.value);
  }

  useEffect(() => {
    dispatch(saveRouteDetails("home-internet") as any);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
      setPageWidth(window.innerWidth);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  return (
    <PageLayout showPartnerNav>
      <title>Home Internet | PhoneBox</title>
      <BGImageSectionWrapper style={{ marginTop: isMobile ? -18 : 0 }}>
        <Container>
          <Row>
            <Col md={true} sm={true} className="px-3" style={{ marginLeft: isMobile ? 15 : 0 }}>
              <CursiveWhiteText style={{ fontSize: isMobile ? 20 : 30 }}>
                <Trans>unlimited dreams</Trans>:
              </CursiveWhiteText>
              <Title>
                <Trans>Your Home Internet Unleashed!</Trans>
              </Title>
              <WhiteText>
                <Trans>Subscribe today & save up to </Trans> 
                <span>&nbsp;</span>
                <span style={{ fontWeight: 600 }}>30%</span>
                <span>&nbsp;</span>
                <Trans> off Market!</Trans>
              </WhiteText>
              <div style={{ paddingTop: 20, display: 'flex', flexDirection: pageWidth < 1400 ? 'column' : 'row' }}>
                <OurButton color={'#E93F25'} width={'260px'} onClick={() => window.open('https://phonebox.vmedia.ca/')}>{t('Shop Home Internet Plans')}</OurButton>
                <div style={{ height: 10, width: 20 }}></div>  
                <OurButton color='' width={'180px'} onClick={() => goTo('/plans')}>
                  {t('See Mobile Plans')}
                </OurButton>
              </div>
            </Col>
            {isMobile &&
              <div style={{ height: 60 }}></div>
            }
            {pageWidth > 1200 &&
              <Col lg={2} md={0}>
                <div style={{ margin: 'auto', position: 'absolute', left: (middlePosition - 250) }}>
                  <img src="/images/internet/woman.png" width={"550px"}/>
                </div>
              </Col>
            }
            {!isMobile &&
              <Col>
                <img src="/images/internet/unlimited-internet.png" width="180px" style={{ paddingLeft: 30, paddingBottom: 20 }}/>
                <div style={{ paddingLeft: 50 }}>
                  <IconRow>
                    <ImgContainer>
                      <img style={{ position: 'absolute', zIndex: 1, marginLeft: 9, marginTop: 6 }} src='/images/internet/person.svg'></img>
                      <img style={{ height: 30, position: 'absolute' }} src='/images/internet/icon-bg.png'></img>
                    </ImgContainer>
                    <WhiteText style={{ paddingLeft: 10 }}>
                      <Trans>Plans for </Trans>
                      <span style={{ fontWeight: 600 }}><Trans>Couples and Bigger Households</Trans></span>
                    </WhiteText>
                  </IconRow>
                  <IconRow>
                    <ImgContainer>
                      <img style={{ height: 30 }} src='/images/internet/phone-icon.png'></img>
                    </ImgContainer>
                    <WhiteText style={{ paddingLeft: 10 }}>
                      <Trans
                        defaults="<span1>Affordable</span1> Mobile Plans"
                        components={{
                          span1: <span style={{ fontWeight: 600 }} />
                        }}
                      />
                    </WhiteText>
                  </IconRow>
                  <IconRow>
                    <ImgContainer>
                      <img style={{ position: 'absolute', zIndex: 1, marginLeft: 8, marginTop: 6, height: 20 }} src='/images/internet/maple-leaf.png'></img>
                      <img style={{ height: 30, position: 'absolute' }} src='/images/internet/icon-bg.png'></img>
                    </ImgContainer>
                    <WhiteText style={{ paddingLeft: 10 }}>
                      <Trans>Only Available in </Trans>
                      <span style={{ fontWeight: 600 }}><Trans>Canada</Trans></span>
                    </WhiteText>
                  </IconRow>
                </div>
              </Col>
            }
          </Row>
        </Container>
      </BGImageSectionWrapper>
      {isMobile &&
        <Col style={{ marginTop: -100 }}>
          <img src="/images/internet/unlimited-internet.png" width="220px" style={{ paddingLeft: 10, paddingBottom: 20 }}/>
          <div style={{ paddingLeft: 50 }}>
            <IconRow>
              <ImgContainer>
                <img style={{ position: 'absolute', zIndex: 1, marginLeft: 9, marginTop: 6 }} src='/images/internet/person.svg'></img>
                <img style={{ height: 30, position: 'absolute' }} src='/images/internet/icon-bg.png'></img>
              </ImgContainer>
              <div style={{ marginTop: pageWidth < 422 ? -20 : 0 }}>
                <BlackText>
                  <Trans>Plans for </Trans>
                  <span style={{ fontWeight: 600 }}><Trans>Couples and Bigger Households</Trans></span>
                </BlackText>
              </div>
            </IconRow>
            <IconRow>
              <ImgContainer>
                <img style={{ height: 30 }} src='/images/internet/phone-icon.png'></img>
              </ImgContainer>
              <BlackText>
                <Trans
                  defaults="<span1>Affordable</span1> Mobile Plans"
                  components={{
                    span1: <span style={{ fontWeight: 600 }} />
                  }}
                />
              </BlackText>
            </IconRow>
            <IconRow>
              <ImgContainer>
                <img style={{ position: 'absolute', zIndex: 1, marginLeft: 9, marginTop: 6 }} src='/images/internet/person.svg'></img>
                <img style={{ height: 30, position: 'absolute' }} src='/images/internet/icon-bg.png'></img>
              </ImgContainer>
              <BlackText>
                <Trans>Only Available in </Trans>
                <span style={{ fontWeight: 600 }}><Trans>Canada</Trans></span>
              </BlackText>
            </IconRow>
          </div>
        </Col>
      }
      <Container>
        <div style={{ marginTop: 80 }}>
          <BlueText>
            <Trans>PhoneBox</Trans>
          </BlueText>
          <BlueTitle>
            <Trans>Exclusive Member Benefit</Trans>
          </BlueTitle>
        </div>
        <Row>
          <Card style={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column', marginLeft: isMobile ? 'auto' : 0, marginRight: isMobile ? 'auto' : 0 }}>
            <img src={isMobile ? '/images/internet/budget-friendly-mini.png' : '/images/internet/budget-friendly-pricing.png'} height='80px'></img>
            <CardInfo>
              <Trans>Budget-friendly pricing options tailored for individuals mindful of their expenses.</Trans>
            </CardInfo>
          </Card>
          {!isMobile && <div style={{ width: "40px" }}></div>}
          <Card style={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column', marginLeft: isMobile ? 'auto' : 0, marginRight: isMobile ? 'auto' : 0 }}>
            <img src={isMobile ? '/images/internet/no-contracts-mini.png' : '/images/internet/no-contracts.png'} height={isMobile ? '100px' : '80px'}></img>
            <CardInfo>
              <Trans>No contracts, no strings attached. Our internet and phone plans offer the freedom to come and go as you please.</Trans>
            </CardInfo>
          </Card>
          {!isMobile && <div style={{ width: "40px" }}></div>}
          <Card style={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column', marginLeft: isMobile ? 'auto' : 0, marginRight: isMobile ? 'auto' : 0 }}>
            <img src={isMobile ? '/images/internet/no-equipment-mini.png' : '/images/internet/no-equipment-deposit.png'} height='80px'></img>
            <CardInfo>
              <Trans>Enjoy our services without any equipment deposit hassle.</Trans>
            </CardInfo>
          </Card>
          {!isMobile && <div style={{ width: "40px" }}></div>}
          <div style={{ width: isMobile ? '320px' : '300px', paddingTop: '10px', marginLeft: isMobile ? 'auto' : 0, marginRight: isMobile ? 'auto' : 0 }}>
            <img src='/images/internet/couple.png' width={isMobile ? '300px' : '280px'}></img>
          </div>
        </Row>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 100 }}>
          <div style={{ maxWidth: isMobile? '378px' : '300px' }}>
            <BlueText>
              <Trans>Our Plans</Trans>
            </BlueText>
            <BlueTitle>
              <Trans>Home Internet Plans</Trans>
            </BlueTitle>
            <Form.Select onChange={onChangeProvince} value={selectedProvince}>
              <option>Alberta</option>
              <option>British Columbia</option>
              <option>Manitoba</option>
              <option>New Brunswick</option>
              <option>Newfoundland and Labrador</option>
              <option>Nova Scotia</option>
              <option>Ontario</option>
              <option>Prince Edward Island</option>
              <option>Quebec</option>
              <option>Saskatchewan</option>
            </Form.Select>
          </div>
          {pageWidth > 992 &&
            <img src="/images/internet/chair-person.png" style={{ position: 'absolute', right: pageWidth > 1400 ? 250 : pageWidth < 1200 ? 0 : 80 }} width="400px"></img>
          }
        </div>
        <Row style={{ marginTop: isMobile ? 0 : 10, marginLeft: isMobile ? 'auto' : 0, marginRight: isMobile ? 'auto' : 0 }}>
          {
            homeInternetPlans.find(elem => elem.province.toLowerCase() === selectedProvince.toLowerCase())?.plans.map(plan => {
              return (
                <OfferCard key={plan.key} isMobile={isMobile}>
                  <div style={{ fontSize: '18px', color: '#0494CA', fontWeight: 600, textAlign: 'center', paddingBottom: 10 }}>{plan.name}</div>
                  <PlanPriceBlue price={plan.price} timeUnit={'month'} currency={'CAD'}/>
                  <div style={{ paddingLeft: 30 }}>
                    <div style={{ fontSize: '12px' }}><Trans>Download</Trans> <span style={{ fontWeight: 600  }}>{plan.download} <Trans>Mbps</Trans></span></div>
                    <div style={{ fontSize: '12px' }}><Trans>Upload</Trans> <span style={{ fontWeight: 600 }}>{plan.upload} <Trans>Mbps</Trans></span></div>
                  </div>
                  <div style={{ fontSize: '14px', color: '#0494CA', fontWeight: 600, textAlign: 'center', padding: 10 }}><Trans>Best for</Trans><span style={{ paddingLeft: 4, paddingRight: 4 }}>{plan.bestFor}</span><Trans>users</Trans></div>
                  <SquareButton onClick={() => window.open('https://phonebox.vmedia.ca/')}>
                    <Trans>Select</Trans>
                  </SquareButton>
                </OfferCard>                
              )
            })
          }
        </Row>
      </Container>
      {!isMobile &&
        <div style={{ backgroundColor: '#0494CA', height: 100, marginTop: 100 }}>
          <Container style={{display: 'flex', flexDirection: 'row', marginLeft: 'auto', marginRight: 'auto' }}>
            <div style={{ color: '#fff', paddingTop: 40, paddingRight: 20, fontSize: '12px' }}>
              <Trans>In Partnership With</Trans>
            </div>
            <img src='/images/internet/vmedia.png' height={20} style={{ marginTop: 40}}></img>
          </Container>
        </div>
      }
      {isMobile &&
        <div style={{ backgroundColor: '#0494CA', height: 60, marginTop: 50, display: 'flex', flexDirection: 'row' }}>
          <div style={{ color: '#fff', paddingTop: 20, paddingLeft: 40, paddingRight: 10, fontSize: '16px' }}>
            <Trans>In Partnership With</Trans>
          </div>
          <img src='/images/internet/vmedia.png' height={20} style={{ marginTop: 20}}></img>
        </div>
      }
      <BGImageSectionWrapper2>
        <Container>
          <Bluebox style={{ marginTop: 20 }}>
            {pageWidth > 992 &&
              <div style={{ borderRadius: '10px', padding: 20, backgroundColor: '#fff', position: 'absolute', right: -25, top: -50 }}>
                <CursiveWhiteText style={{ borderRadius: '10px', backgroundColor: '#FDCF00', maxWidth: 160, padding: 5, textAlign: 'center', fontSize: '20px' }}>
                  <Trans>Offer</Trans> • <Trans>Phone Plans</Trans>
                </CursiveWhiteText>
                <div style={{ color: '#0494CA', maxWidth: 160, fontSize: '14px', marginTop: 10, lineHeight: 1.1 }}>
                  <Trans>Extend Your Surfing Experience with our Mobile Plans!</Trans>
                </div>
              </div>
            }
            <div style={{ textAlign: isMobile ? 'left' : 'center', padding: isMobile ? 20 : 0 }}>
              <div style={{ color: '#fff' }}>
                <Trans>Help</Trans>
              </div>
              <h3 style={{ color: '#fff', fontWeight: 600 }}>
                <Trans>How it works</Trans>
              </h3>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'  }}>
              <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: isMobile ? 0 : 30, paddingBottom: 30 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <div style={{ width: isMobile ? 'auto' : '240px', display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}>
                    <ImgContainer>
                      <img style={{ position: 'absolute', zIndex: 1, marginLeft: 8, marginTop: 8, height: 25 }} src='/images/internet/click.svg'></img>
                      <img style={{ height: 42, position: 'absolute' }} src='/images/internet/circle.svg'></img>
                    </ImgContainer>
                    <InfoText><span style={{ fontWeight: 600 }}>1.</span> <Trans>Choose your plan</Trans></InfoText>
                  </div>
                  <div style={{ width: isMobile ? 'auto' : '240px', alignContent: 'center', display: 'flex', flexDirection: isMobile ? 'row' : 'column', marginTop: isMobile? 10 : 0 }}>
                    <ImgContainer>
                      <img style={{ position: 'absolute', zIndex: 1, marginLeft: 8, marginTop: 10, height: 20 }} src='/images/internet/email.svg'></img>
                      <img style={{ height: 42, position: 'absolute' }} src='/images/internet/circle.svg'></img>
                    </ImgContainer>
                    <InfoText><span style={{ fontWeight: 600 }}>2.</span> <Trans>Receive the confirmation email from VMedia</Trans></InfoText>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 10 }}>
                  <div style={{ width: isMobile ? 'auto' : '240px', display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}>
                    <ImgContainer>
                      <img style={{ position: 'absolute', zIndex: 1, marginLeft: 11, marginTop: 10, height: 20 }} src='/images/internet/router.svg'></img>
                      <img style={{ height: 42, position: 'absolute' }} src='/images/internet/circle.svg'></img>
                    </ImgContainer>
                    <InfoText><span style={{ fontWeight: 600 }}>3.</span> <Trans>Receive your equipment</Trans></InfoText>
                  </div>
                  <div style={{ width: isMobile ? 'auto' : '240px', display: 'flex', flexDirection: isMobile ? 'row' : 'column', marginTop: isMobile? 10 : 0 }}>
                    <ImgContainer>
                      <img style={{ position: 'absolute', zIndex: 1, marginLeft: 8, marginTop: 10, height: 20 }} src='/images/internet/wifi.svg'></img>
                      <img style={{ height: 42, position: 'absolute' }} src='/images/internet/circle.svg'></img>
                    </ImgContainer>
                    <InfoText><span style={{ fontWeight: 600 }}>4.</span> <Trans>Connect to the internet and start surfing!</Trans></InfoText>
                  </div>
                </div>
              </div>
              <div style={{ padding: isMobile ? 0 : 30, width: isMobile ? `100%` : 'auto' }}>
                <img src='/images/internet/couple3.png' style={{ borderRadius: '10px' }} width={isMobile ? '100%' : '380px'}></img>
              </div>
            </div>
            {pageWidth <= 992 &&
              <SmallOfferCard>
                <CursiveWhiteText style={{ borderRadius: '10px', backgroundColor: '#FDCF00', maxWidth: 220, padding: 5, textAlign: 'center', fontSize: '20px' }}>
                  <Trans>Offer</Trans> • <Trans>Phone Plans</Trans>
                </CursiveWhiteText>
                <div style={{ color: '#0494CA', maxWidth: 260, fontSize: '16px', marginTop: 10, lineHeight: 0.9 }}>
                  <Trans>Extend Your Surfing Experience with our Mobile Plans!</Trans>
                </div>
              </SmallOfferCard>
            }
          </Bluebox>
        </Container>
      </BGImageSectionWrapper2>
      <Container style={{ marginTop: isMobile ? 50 : 30 }}>
        <BlueText>
          <Trans>STAY CONNECTED</Trans>
        </BlueText>
        <BlueTitle>
          <Trans>Enjoy Exclusive Mobility Offers</Trans>
        </BlueTitle>
        <CursiveWhiteText style={{ borderRadius: '10px', backgroundColor: '#FDCF00', maxWidth: 240, padding: 5, textAlign: 'center', marginTop: 20, fontSize: '20px' }}>
          <Trans>Monthly Plans</Trans> • <Trans>Plans from</Trans>
        </CursiveWhiteText>
        {!isMobile &&
          <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 50 }}>
            <OfferCard isMobile={false}>
              <PlanPriceBlue price={28} timeUnit={'month'} currency={'CAD'}/>
              <div style={{ fontWeight: 600, color: '#0494CA', textAlign: 'center', marginBottom: 10, fontSize: '20px' }}>20 GB</div>
              <SquareButton onClick={() => goTo('/plans')}>{t('Sign Up')}</SquareButton>
            </OfferCard>
            <WideOfferCard>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <div style={{ padding: 20, position: 'relative' }}>
                  <div style={{ fontWeight: 600, fontSize: '14px', paddingBottom: 5 }}>
                    <Trans>All our Plans include</Trans>:
                  </div>
                  <div style={{ fontSize: '14px', lineHeight: 1.75 }}>• <Trans>UNLIMITED Canada-wide calling</Trans></div>
                  <div style={{ fontSize: '14px', lineHeight: 1.75 }}>• <Trans>UNLIMITED International texting</Trans></div>
                  <SquareButton style={{ position: 'absolute', bottom: 20 }} onClick={() => goTo('/plans')}>{t('View Plans')}</SquareButton>
                </div>
                <div style={{ padding: 20, borderLeftWidth: 1, borderLeftStyle: 'solid', borderColor: '#EDEDED' }}>
                  <div style={{ fontWeight: 600, fontSize: '14px', paddingBottom: 5 }}><Trans>PhoneBox Benefits</Trans>:</div>
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                    <FontAwesomeIcon style={{ color: '#0494CA', marginRight: 5, alignContent: 'center' }} icon={faSquareCheck} fontSize={16}></FontAwesomeIcon>
                    <div style={{ fontSize: '14px' }}>
                      <Trans>5G Network Speed</Trans>
                    </div>                  
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                    <FontAwesomeIcon style={{ color: '#0494CA', marginRight: 5, alignContent: 'center' }} icon={faSquareCheck} fontSize={16}></FontAwesomeIcon>
                    <div style={{ fontSize: '14px' }}>eSIM</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                    <FontAwesomeIcon style={{ color: '#0494CA', marginRight: 5, alignContent: 'center' }} icon={faSquareCheck} fontSize={16}></FontAwesomeIcon>
                    <div style={{ fontSize: '14px' }}>
                      <Trans>Multilingual Customer Service</Trans>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                    <FontAwesomeIcon style={{ color: '#0494CA', marginRight: 5, alignContent: 'center' }} icon={faSquareCheck} fontSize={16}></FontAwesomeIcon>
                    <div style={{ fontSize: '14px' }}>
                      <Trans>Free Worldwide Shipping</Trans>
                    </div>
                  </div>
                </div>
              </div>
            </WideOfferCard>
          </div>
        }
        {isMobile &&
          <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 50 }}>
            <MobileOfferCard>
              <PlanPriceBlue price={28} timeUnit={'month'} currency={'CAD'}/>
              <div style={{ fontWeight: 600, color: '#0494CA', textAlign: 'center', marginBottom: 10 }}>20 GB</div>
              <SquareButton onClick={() => goTo('/plans')}>Sign Up</SquareButton>
            </MobileOfferCard>
            <WideOfferCard>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                <div style={{ padding: 20, borderBottomWidth: 1, borderBottomStyle: 'solid', borderColor: '#EDEDED' }}>
                  <div style={{ fontWeight: 600, fontSize: '14px' }}>PhoneBox Benefits:</div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FontAwesomeIcon style={{ color: '#0494CA', marginRight: 5, alignContent: 'center' }} icon={faSquareCheck} fontSize={16}></FontAwesomeIcon>
                    <div style={{ fontSize: '14px' }}><Trans>5G Network Speed</Trans></div>                  
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FontAwesomeIcon style={{ color: '#0494CA', marginRight: 5, alignContent: 'center' }} icon={faSquareCheck} fontSize={16}></FontAwesomeIcon>
                    <div style={{ fontSize: '14px' }}>eSIM</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FontAwesomeIcon style={{ color: '#0494CA', marginRight: 5, alignContent: 'center' }} icon={faSquareCheck} fontSize={16}></FontAwesomeIcon>
                    <div style={{ fontSize: '14px' }}>Multilingual Customer Service</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <FontAwesomeIcon style={{ color: '#0494CA', marginRight: 5, alignContent: 'center' }} icon={faSquareCheck} fontSize={16}></FontAwesomeIcon>
                    <div style={{ fontSize: '14px' }}>Free Worldwide Shipping</div>
                  </div>
                </div>
                <div style={{ padding: 20, position: 'relative' }}>
                  <div style={{ fontWeight: 600, fontSize: '14px' }}>All our Plans include:</div>
                  <div style={{ fontSize: '14px' }}>• UNLIMITED Canada-wide calling</div>
                  <div style={{ fontSize: '14px' }}>• UNLIMITED International texting</div>
                  <SquareButton style={{ marginTop: 20 }} onClick={() => goTo('/plans')}>View Plans</SquareButton>
                </div>
              </div>
            </WideOfferCard>
          </div>
        }
        <LearnMoreSection country={shopDetails.country} page={"home-internet"} />
      </Container>
    </PageLayout>
  )
}

const OurButton = styled.div<{ color: string, width: string }>`
  border-style: solid;
  border-radius: 10px;
  border-color: ${props => props.color === '' ? '#fff' : props.color};
  border-width: 1px;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  color: #fff;
  background-color: ${props => props.color ? props.color : 'rgba(0,0,0,0)'};
  width: ${props => props.width};
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  font-weight: 600;
  cursor: pointer;
`

const BGImageSectionWrapper = styled.div`
  margin: 0px 0px;
  padding: 80px 0px;
  background-image: url('/images/internet/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media only screen and (max-width: 768px) {
    margin: 5px 0px;
  }
  @media (min-width: 769px) {
    background-image: url('/images/internet/background2.png');
  }
`

const BGImageSectionWrapper2 = styled.div`
  margin: 0px 0px;
  padding: 80px 0px;
  background-image: url('/images/partners/fade-stock-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
  @media only screen and (max-width: 768px) {
    margin: 5px 20px;
  }
`

const Title = styled.h3`
  font-weight: 600;
  color: #fff;
  font-size: 45px;
`

const WhiteText = styled.div`
  color: #fff;
  height: 30px;
`

const CursiveWhiteText = styled.div`
  color: #fff;
  font-family: Damion;
`

const BlackText = styled.div`
  color: #000;
  height: 30px;
`

const InfoText = styled.div`
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  @media only screen and (max-width: 768px) {
    margin-left: 15px;
  }
`

const ImgContainer = styled.div`
  height: 42px;
  width: 42px;
`

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 768px) {
    height: 50px;
  }
`

const Bluebox = styled.div`
  background-color: #0494CA;
  border-radius: 10px;
  padding: 20px;
  margin-right: 100px;
  margin-left: 100px;
  position: relative;
  @media only screen and (max-width: 768px) {
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
  }
`

const BlueTitle = styled.h3`
  font-weight: 600;
  color: #0494CA;
  font-size: 30px;
`

const BlueText = styled.p`
  color: #0494CA;
  margin-bottom: 0px !important;
`

const Card = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.09);
  padding: 10px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  @media (min-width: 769px) {
    padding-top: 35px;
  }
`



// const ContainerWidth = styled.div`
//   @media (min-width: 1440px) {

//   }
// `

const CardInfo = styled.div`
  font-size: 12px;
  text-align: center;
  padding: 10px;
`

const OfferCard = styled.div<{ isMobile: boolean }>`
  margin-right: ${props => props.isMobile ? 'auto' : '16px'};
  margin-top: 16px;
  margin-left: ${props => props.isMobile ? 'auto' : '0px'};
  padding: 20px;
  border-radius: 10px;
  max-width: ${props => props.isMobile ? '300px' : '220px'};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.09);
`

const MobileOfferCard = styled.div`
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.09);
`

const SmallOfferCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 260px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.09);
  position: absolute;
  bottom: -80px;
  left: 0;
  right: 0;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
`

const WideOfferCard = styled.div`
  margin-right: 16px;
  margin-top: 16px;
  border-radius: 10px;
  max-width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.09);
  @media only screen and (max-width: 768px) {
    max-width: 300px;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
  }
`

const SquareButton = styled.div`
  border-style: solid;
  border-radius: 10px;
  border-color: #0494CA;
  border-width: 1px;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  color: #0494CA;
  width: 140px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-weight: 600;
  cursor: pointer;
`

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
